import React from 'react';
import Layout from '../../components/common/Layout';
import RightToBeForgottenVerify from '../../components/from/RightToBeForgotten/RightToBeForgottenVerify';
import SEO from '../../components/SEO';

const RightToBeForgottenVerifyPage = () => (
  <Layout lang="en">
    <SEO
      title="Right to be forgotten verify - Nextcloud Hosting"
      description="Verify, please verify the deletion process."
    />
    <RightToBeForgottenVerify lang="en" />
  </Layout>
);

export default RightToBeForgottenVerifyPage;
